import React from 'react'
import { Paper, Box } from '@mui/material'
import VideoSummaryCard from './VideoSummaryCard'
import VideoDemoCard from './VideoDemoCard'
import VideoStackSkillsCard from './VideoStackSkillsCard'
import VerticalDividerCard from './VerticalDividerCard'
import VideoTitleCard from './VideoTitleCard'

export default function VideosCardSmall({title, summary, videoUrl, poster, stack, skills}) {
  return (
    <Box sx={{maxWidth: '100wv'}}>
        <Box sx={{mb:12}}>
            <VerticalDividerCard text={'scroll creative'} />
        </Box>
        <VideoTitleCard
            title={title}
        />
        <Paper sx={{p:1, mt:2, backgroundColor: 'primary.light', border:'1px solid lightGrey'}}>
            <Paper elevation={0} sx={{p:1}}>
                <VideoSummaryCard 
                    summary={summary}
                />
            </Paper>    
            <Paper elevation={0} sx={{p:1,mt:1}}>
                <VideoDemoCard
                    videoUrl={videoUrl}
                    poster={poster}
                />
            </Paper>
            <Paper elevation={0} sx={{p:1,mt:1,mb:1}}>
                <VideoStackSkillsCard 
                    stack={stack} 
                    skills={skills}
                />
            </Paper>   
        </Paper>
  </Box>
  )
}
