export const profileData = {
    name: 'Michael Mallette',
    phone: '416 / 738 / 2043',
    email: 'michael.mallette @ gmail',
    title: 'Designer',
    titleTwo: 'Developer' ,
    titleThree:'Creator' ,
    titleFour: 'Dad',
    motto: `My aim is to blend the precision and functionality of software with the calming and soothing qualities of nature. 
    I believe software can do more than simply meet our needs, it can also provide a sense of joy and serenity that is essential for our well-being.`,
    mottoTitle: 'Inspiration',
    summaryTitle: 'A bit about me...',
    summary: `I love spending time with my children, and being their father is my favourite part of life. They keep me on my toes and we remind each other life is about the pursuit of happiness.  We laugh a lot.
        In my free time, I'm a coding junkie. I love tinkering with computers, writing software, and building progressive web apps. It's a never-ending puzzle that I can't get enough of.
        But I also like to stay active and try new things. I'm always up for a challenge, whether it's rock climbing or snowboarding or even martial arts. Bring on the adrenaline rush!
        And when I'm not pushing myself physically, I'm a big fan of the arts, especially painting and digital media. And I never say no to a chance to travel the world and experience new cultures.`,
    
    designSkillsTitle: 'My design thoughts...',
    designSkills: `As an inspired software developer, I believe designing software interfaces is an art form that can be inspired by the natural world around us. 
    My passion for organic and sustainable design has led me to draw inspiration from the rich colors and textures of nature when creating stunning software designs that capture the imagination. 
    I apply this approach to any software product that I work on, whether it's an app or a website.`,
 
    techSkillsTitle: 'My technical thoughts...',
    techSkills: `As an award-winning entrepreneur and software developer, I possess highly valued skills and adaptability that can benefit the tech industry greatly. My experience as an entrepreneur has taught me the significance of taking proactive measures, being creative, and adapting quickly to changing circumstances. Furthermore, through my software development background, I have acquired a thorough comprehension of technology and designing scalable and effective solutions. As someone who is always eager to learn, I look forward to collaborating with others and utilizing their expertise and experience as we work towards solving the world's next big problems.`,
    imgUrl: 'images/michaelMallette.jpg',
    imgHoverUrl: 'images/viUmbrella.jpg',
    animalHoloUrl: '/images/lightPanda.jpg',
    animalHoloUrlDark: '/images/darkPanda.jpg',
}


//dark AI image prompt: a smiling techno steampunk anime animal with the top part of it's brain exposed made entirely of plants.  Should be composed of either analogue radio parts or plant parts, mostly plants, and look similar to a baby monkey with kind eyes facing forward.  The top of it's head should be a glowing hologram with an exposed organic brain.  the entire image should be visible in frame, and background should be solid black