import React from 'react'
import { Stack, Box } from '@mui/material'
import ProfilePictureCard from '../cards/ProfilePictureCard'
import AnimalHoloCard from '../cards/AnimalHoloCard'
import { profileData } from '../../data/profileData'
import AboutParagraphCard from '../cards/AboutParagraphCard.jsx'
import TechSkillsCard from '../cards/TechSkillsCard'
import DesignSkillsCard from '../cards/DesignSkillsCard'
import NameAndTitleTile from '../tiles/NameAndTitleTile'

export default function AboutTile() {
    const {summary, name, title, summaryTitle, designSkills, techSkills, imgUrl, imgHoverUrl, animalHoloUrl, animalHoloUrlDark, designSkillsTitle, techSkillsTitle} = profileData; 

    return (
    <Box sx={{p:1, maxWidth: '100vw'}}>
        <Stack 
            sx={{
                display:'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                maxWidth: '800px',
            }}
            direction='column' 
            gap={2}
        >   
            <ProfilePictureCard imgUrl={imgUrl} imgHoverUrl={imgHoverUrl} />
            <Box sx={{mt:3, mb:3}}>
                <NameAndTitleTile name={name} title={title} />
            </Box>
            <Box sx={{m:-6, display:{xs:'none', sm:'none',md:'block'}}}>
                <AnimalHoloCard 
                    imgUrl={animalHoloUrl} 
                    imgUrlDark={animalHoloUrlDark}                               
                /> 
            </Box>
            <AboutParagraphCard title={summaryTitle} text={summary} />
            <TechSkillsCard />
            <AboutParagraphCard title={techSkillsTitle} text={techSkills} />
            <DesignSkillsCard />
            <AboutParagraphCard title={designSkillsTitle} text={designSkills} />
        </Stack>
    </Box>
  )
}
