import React from 'react'
import { Typography, Box } from '@mui/material'
import { grey } from '@mui/material/colors'

const colorGrey = {...grey};

const container = {
    display: 'flex',
    flexFlow: 'row noWrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: 175,
}
const boxLeft = {
    flex: '1 0',
}
const boxRight = {
    flex: '1 0',
    borderLeft: `1px solid ${colorGrey[500]}`, 
    height: '100%',
}

export default function VerticalDividerCard({text}) {
  return (
    <>
        <Typography sx={{display:'flex', justifyContent: 'center', alignItems: 'center', mt:4, mb:4}} 
            variant='caption' 
            color='secondary.main' 
            component='div'
        >
            {text}
        </Typography>
        <Box style={container}>
            <Box style={boxLeft}/>
            <Box style={boxRight}/>
        </Box>
    </>
  )
}
