import React, { useContext } from 'react';
import { ColorModeContext } from '../../App';
import { Stack, Avatar, Box, Typography } from '@mui/material';
import DarkMode from '@mui/icons-material/DarkMode';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NoteIcon from '@mui/icons-material/Note';
import { useNavigate } from 'react-router-dom';
import { profileData } from '../../data/profileData';

const headerStyle = {
    position: 'fixed',
    top: 0,
    width: '100vw', 
    minHeight: 65,
    opacity: 0.75,
    zIndex: 10,
}

export default function Header() {
  const themeContext = useContext(ColorModeContext); 
  const navigate = useNavigate();
  
  const { phone, email }= profileData;

  const handleClickAboutMe = () => {
    navigate('/aboutMe');
  }
  const handleClickHome = () => {
    navigate('/');
  }
  const handleClickReferences = () => {
    navigate('/references');
  }


  return (
      <Box backgroundColor='primary.light' sx={{boxShadow:1}} style={headerStyle}>
        <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <Avatar sx={{mb: 1, mt:2, ml:2, mr: 4, boxShadow:1}} alt="Michael Mallette" src="./images/michaelMallette.jpg" />
        </Box>
          <Typography variant='caption' color='secondary.main'>
                <Box sx={{ml:2, mb:2, display: 'flex', justifyContent: 'space-between'}}>
                  <span>{phone}</span><span style={{marginLeft: 16, marginRight: 24}}>{email}</span>
                </Box>
          </Typography>
       <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="stretch"
            spacing={2}
            style={{position: 'absolute', top: 24, right: 24}}
            sx={{color: 'text.main'}}
        >
            <HomeOutlinedIcon onClick={handleClickHome} />
            <ContactsOutlinedIcon onClick={handleClickAboutMe} />
            <NoteIcon onClick={handleClickReferences} />
            <DarkMode onClick={themeContext.toggleColorMode} />
        </Stack>
      </Box>
    )
}
