import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';

const imgStyle = {
  width: '800px',
  maxWidth: '95vw',
  overflow: 'hidden',
  objectFit: 'cover',
}

export default function ReferenceCard({title, company, name, jobTitle, totalPages, pageOne, pageTwo}) {
    const [pageNumber, setPageNumber] = useState(1);
    const handleNext = () => {
      pageNumber < totalPages && setPageNumber((prevState) => prevState +1);
    }
    const handlePrev = () => {
      pageNumber > 1 && setPageNumber((prevState) => prevState -1);
    }
    useEffect(() => {
      setPageNumber(1);
    },[])
    
    return (
      <>
        <Stack direction='column' gap={1} 
          sx={{mt:2, display:'flex', flexFlow:'column wrap', alignItems:'stretch', justifyContent:'center'}}
        >
          <Typography sx={{mt:2}} variant='subtitle1' color='secondary.main'>
              {title}
          </Typography>
        <Paper elevation={9} sx={{p:1, border:'1px solid lightGrey'}}>
          <Typography variant='subtitle2' color='primary.dark'>
              <Box>{name}</Box>
          </Typography>
          <Typography variant='h4' color='text.main'>
              <Box>{jobTitle}</Box>
              <Box>{company}</Box>
          </Typography>
        </Paper>
        </Stack>
      <Paper 
          elevation={9} 
          sx={{mt:2, p:1, border:'1px solid lightGrey',
              display:'flex', flexFlow: 'column noWrap', alignItems: 'center', justifyContent: 'center',
      }}>
          {pageNumber === 1 ? 
            <Box>
              <img style={imgStyle} src={pageOne} alt='page 1 of Adobe reference letter' />
            </Box>
              :
            <Box>
              <img style={imgStyle} src={pageTwo} alt='page 2 of Adobe reference letter' />  
            </Box>
          }
          <Stack direction='row' gap={2}>
            <Box sx={{cursor: 'pointer', mr:1}} onClick={handlePrev}>Prev</Box>
            <Box>{pageNumber} of 2</Box>
            <Box sx={{cursor: 'pointer', ml:1}} onClick={handleNext}>Next</Box>
          </Stack>
      </Paper>
    </>
  )
}