import React from 'react'
import { Typography, Paper } from '@mui/material'

export default function AboutParagraphCard({title, text}) {
  return (
    <Paper elevation={9} sx={{p:1, border:'1px solid lightGrey'}}>
        <Typography sx={{mb:1}} variant='subtitle1' color='secondary.main'>
            {title}
        </Typography>
        <Typography variant='body1' component='div'>
            {text}
        </Typography>
    </Paper>
  ) 
}
