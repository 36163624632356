import React from 'react'
import { Box, Stack, Typography, Paper } from '@mui/material'
import ProjectVideoCard from './ProjectVideoCard'
import VerticalDividerCard from './VerticalDividerCard'

export default function ProjectCardSmall({
        title,
        summary,
        result,
        stack,
        skills,
        videoUrl,
        videoMaxWidth,
        textColour,
    }){
    
    return (
        <div style={{
            display: 'flex', 
            flexFlow: 'column wrap', 
            alignItems:'center', 
            justifyContent: 'center',
            padding: 1,
            margin: 0,
            }}>
        <Box sx={{mb:12}}>
            <VerticalDividerCard text={'scroll software'} />
        </Box>
        <Typography sx={{p:1, textAlign: 'center'}} color='secondary.main' variant='subtitle1' component='span'>
            Project:
            <Typography sx={{ml:1}} color='text.dark' variant='subtitle1' component='span'>
                {title}
            </Typography>
        </Typography>
        <Paper sx={{backgroundColor: 'secondary.light', p:1, mt: 2, border: '1px solid lightGrey'}}>
            <Stack direction='column' gap={2}>
                <Paper elevation={9} sx={{
                        border: '1px solid lightGrey',
                        display: 'flex',
                        flexFlow: 'column wrap',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                }}>
                    <Typography sx={{p:1}} variant='subtitle2' color='secondary.main' component='div'>
                        Overview
                    </Typography>
                    <Typography 
                        variant='body1' 
                        color={textColour}
                        component='div' 
                        sx={{p:2, pt:1}}>
                        {summary}
                    </Typography>
                </Paper>

                <Paper elevation={9} sx={{
                    border: '1px solid lightGrey',
                    display: 'flex',
                    flexFlow: 'column wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                    <Typography sx={{p:1}} variant='subtitle2' color='secondary.main' component='div'>
                        Result
                    </Typography>
                    <Typography 
                        color={textColour}
                        variant='body1' 
                        component='div' 
                        sx={{p:2, pt:1}}>
                        {result}
                    </Typography>
                </Paper>
                <Paper elevation={9} sx={{
                    p:1,
                    border: '1px solid lightGrey',
                    display: 'flex',
                    flexFlow: 'column wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}>
                <ProjectVideoCard videoUrl={videoUrl} videoMaxWidth={videoMaxWidth} />
                </Paper>
                <Paper elevation={9} sx={{
                    p:1,
                    gap:1,
                    border: '1px solid lightGrey',
                    display: 'flex',
                    flexFlow: 'column wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                <Typography variant='subTitle2' component='span'>
                    <Typography sx={{mr:1}} color='secondary.main' component='span'>
                        Solutions Stack: 
                    </Typography> 
                    {stack}
                </Typography>
                <Typography variant='subTitle2' component='span'>
                    <Typography sx={{mr:1}} color='secondary.main' component='span'>
                        Skills: 
                    </Typography> 
                        {skills}
                </Typography>
                </Paper>
            </Stack>
        </Paper>
        </div>
  )
}
