import { grey, blue, } from '@mui/material/colors';

export const getDesignTokens = (mode) => ({
        spacing: 8, 
        palette: {
                mode,
                primary: {
                ...blue,
                ...grey,
                        ...(mode === 'light' ?
                                {
                                        light: blue[200], 
                                        main: blue[500], //#2196f3
                                        dark: blue[700],
                                        contrastText: 'grey[500]',
                                } : {
                                        light: blue[200], //same as above...place holder to quickly change dark mode palette if required
                                        main: blue[500],
                                        dark: blue[700],
                                        contrastText: 'grey[500]',
                                }
                        )
                },
                secondary: {
                ...grey,
                        ...(mode === 'light' ? 
                                {
                                        light: '#ff599e',
                                        main: '#c91e66',
                                        dark: '#91013d',
                                        contrastText: '#1f1f1f',
                                }: {
                                        light: '#fa5f94', //same as above...place holder to quickly change dark mode palette if required
                                        main: '#e91e63',
                                        dark: '#cf0046',
                                        contrastText: '#fafafa',
                                }
                        )
                },
                background: {
                        ...(mode === 'light' ? 
                        {
                                default: '#fff',
                                paper: '#fff',
                        } :{
                                default: '#000',
                                paper: '#000',
                        })
                },
                text: {
                        ...(mode === 'light' ? 
                        {
                                default:'#242424',
                                light: '#fafafa',
                                main: '#949494',
                                dark: '#424242',
                                paper: '#fff',
                        } :{
                                default: '#f7f7f7',
                                paper: '#fafafa',
                        })
                }
        },
        typography: {
                  fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        'Open Sans',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                ].join(','),
               
                h1: {
                        fontSize: '3.25rem',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: '0.250rem',
                        lineHeight: 1.25,
                },
                h2: {
                        fontSize: '2.25rem', 
                        fontFamily: 'Open Sans Light',
                        letterSpacing: '0.250rem',
                        lineHeight: 1.25,
                },
                h3: {
                        fontSize: '1.75rem',
                        fontFamily: 'Open Sans Light',
                        fontWeight: '300',
                        letterSpacing: '0.115rem',
                        lineHeight: 1.25,
                },
                h4: {
                        fontSize: '1.0rem',
                        fontWeight: '300',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: '0.050rem',
                        lineHeight: 1.25,
                },
                h5: {
                        fontSize: '0.83rem',
                        fontWeight: '300',
                        fontFamily: 'Open Sans',
                        letterSpacing: '.025rem',
                        lineHeight: 1.25,
                },
                h6: {
                        fontSize: '0.67rem',
                        fontWeight: '300',
                        fontFamily: 'Open Sans',
                        letterSpacing: '0.025rem',
                        lineHeight: 1.5,
                },
                body1: {
                        fontSize: '1rem',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: "0.025rem",
                        lineHeight: 1.25,
                        },
                body2: {
                        fontSize: '0.83rem',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: "0.035rem",
                        lineHeight: 1.15,
                },
                subtitle1: {
                        fontSize: '1.45rem',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: '0.225rem',
                        lineHeight: 1.25,
                },
                subtitle2: {
                        fontSize: '1.25rem',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: '0.225rem',
                        lineHeight: 1.25,
                },
                caption: {
                        fontSize: '0.80rem',
                        fontFamily: 'Open Sans Light',
                        letterSpacing: '0.065rem',
                        lineHeight: 1.10,  
                },
        },
}) 
                
        