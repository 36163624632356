import React from 'react'
import TitleBackgroundImgCardSm from '../cards/TitleBackgroundImgCardSm'
import TitleBackgroundImgCardLg from '../cards/TitleBackgroundImgCardLg'
import { Box } from '@mui/material'

const container = {
    display: 'flex',
    flexFlow: 'column noWrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100vw',
}

export default function MainHeaderTile() {
    return (
        <div style={container}>
        <Box sx={{display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'}}}>
            <TitleBackgroundImgCardSm />
        </Box>
        <Box sx={{display: {xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block'}}}>
            <TitleBackgroundImgCardLg />
        </Box>
      </div>
    )
}

