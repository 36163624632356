import React from 'react'
import { Typography } from '@mui/material'

export default function VideoTitleCard({title}) {
  return (
    <>
        <Typography sx={{p:1, display:'flex', justifyContent: 'center'}} 
        color='primary.main' 
        variant='subtitle1' component='span'
        >
            Project:
            <Typography sx={{ml:1}} color='text.dark' variant='subtitle1' component='span'>
                {title}
            </Typography>
        </Typography>
    </>
  )
}
