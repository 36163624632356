import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom"
import ErrorPage from "./pages/ErrorPage"
import Root from './components/nav/Root'
import HomePage from "./pages/HomePage"
import AboutPage from './pages/AboutPage'
import ReferencePage from './pages/ReferencePage'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
          element={<Root />}
          path="/"
          errorElement={<ErrorPage />}
      >
          <Route index element={<HomePage />} />
          <Route path='/aboutMe' element={<AboutPage />} />
          <Route path='/references' element={<ReferencePage />} />
          <Route path= "*" element={"Hmmm, nothing to see here."}/>
      </Route>
    </>
  )
);

export default router;
