import React from 'react';
import { Box, Paper } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';

const paperStyle = {
        position: 'absolute',
        bottom: 0,
        margin: 0,
        marginBottom: -32, 
        paddingTop: 8,
        left: 0,
        right: 0,
        maxWidth: '100vw',
        height: 60,
}

export default function Footer() {
    const year = new Date().getFullYear(); 
    return (
        <>    
            <Paper elevation={9} style={paperStyle}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'static'}}>
                    <span><CopyrightIcon fontSize='small' style={{marginTop: 6}} /></span>
                    <span style={{marginLeft: 8}}>Michael Mallette, {year}</span>
                </Box>
            </Paper>
        </>
    );
}


