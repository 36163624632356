
//projects should all have a summary description and result and either an imgUrl & imgAlt key, OR a videoUrl key.
export const projects = [
    {   id: 11789,
        projectName: 'bffProject',
        title: 'PetExec U/I Redesign',
        summary:  "By leveraging their pre-existing back-end API, I skillfully reimagined the interface of PetExec to remedy customer grievances regarding its perplexing design. The revamped UI/UX focuses on driving sales and incorporates a customer shopping cart in tandem with a multi-national payment gateway.",
        result:  "The outcomes dramatically benefited the operation, with a 90% decrease in service calls, 95% automation of new apt bookings, a 45% increase in seat-sale revenue from Lightning Sales, and booking monthly services can be completed in under a minute, a significant improvement from 15 minutes.",
        stack: 'ReactJS, JavaScript, HTML5, CSS3, 3rd-Party API, Material Design',
        skills: 'Complex-State-Management, Custom Hooks, Date/Time Functionality, Custom Shopping Cart & Payment Gateway',
        videoUrl: './videos/bffAppDemo1080x2200.mp4',
        videoMaxWidth: 225,
        holoAnimalUrl: './images/lightOwl.jpg',
        holoAnimalUrlDark:'./images/darkOwl.jpg',
    },
    {   id: 22895,
        projectName: 'meCapsules',
        title: 'meCapsules',
        summary:  "I had a personal need to effectively digitize my life without losing quick access to precious memories like my children’s artwork.  meCapsules solved the challenge.  The meCapsules cloud ecosystem is a high-performance, mobile-first solution for efficiently capturing and organizing memories.",
        result:  "I can now efficiently gather, store, and categorize my family's cherished keepsakes, including artwork, report cards, toys, and other significant artifacts without the need to sift through countless photos and videos.",
        stack: 'ReactJS, JavaScript, HTML5, CSS3, Google Firebase, Material Design',
        skills: 'Complex-State-Management, Custom Hooks, Database Design, Real-Time Database View, Media Compression & Display',
        videoUrl: './videos/meCapsulesDemo720x1600.mp4',
        videoMaxWidth: 215,
        holoAnimalUrl: './images/lightSeal.jpg',
        holoAnimalUrlDark:'./images/darkSeal.jpg',
    },
]

export const videos = [
    {   id: 12377,
        projectName: '',
        title: 'BFF App Spot',
        summary: `The aim in producing this demonstration video was to emphasize the correlation between the user experience facilitated by the application and the pet experience enabled by utilizing the application.`,
        videoUrl: `./videos/bffAddDemo_1920x1080_V2.mp4`,
        poster: `./images/bffAppPosterV2.jpg`,
        stack: 'Shot with a Canon XLR run-and-gun camcorder, Adobe After Effects, Adobe Premiere Pro, Adobe Illustrator, Adobe Photosphop, Adobe Media Encoder',
        skills: 'complex-work-flow management, videography, compositing, sound-editing/mixing, video-editing, special effects, video compression',
        holoAnimalUrl: './images/lightPanda.jpg',
        holoAnimalUrlDark:'./images/darkPanda.jpg',
    },
    {   id: 23488,
        projectName: '',
        title: 'BFF Vacations',
        summary: `The primary objective of the project was to produce a video which represented 'a-day-in-the-life' of pups enjoying their vacations.  
            The video was designed to educate and engage potential clients to enhance trust & confidence in the brand.`,
        videoUrl: `./videos/bffBoarding.mp4`,
        poster: `./images/bffVacationsPoster.png`,
        stack: 'Shot with a Samsung S9+ smart phone, Adobe After Effects, Adobe Premiere Pro, Adobe Illustrator, Adobe Photosphop, Adobe Media Encoder',
        skills: 'complex-work-flow management, videography, compositing, sound-editing/mixing, video-editing, special effects, video compression',
        holoAnimalUrl: './images/lightLlama.jpg',
        holoAnimalUrlDark:'./images/darkLlama.jpg',
    }
]