import React, {useState, useEffect}  from 'react'
import { projects } from '../../data/projects'
import ProjectCard from '../cards/ProjectCard'
import AnimalHoloCard from '../cards/AnimalHoloCard'
import { useTheme, Box } from '@mui/material'
import ProjectCardSmall from '../cards/ProjectCardSmall'

export default function ProjectsTile() {
  const [textColour, setTextColour] = useState('text.dark');
  const { palette } = useTheme();

  useEffect(() => {
      palette.mode === 'dark' ? setTextColour('black') : setTextColour('text.dark')
  }, [palette.mode]);

  return (
    <div>
          {projects !== undefined && 
              projects?.map((obj) => {
                  return (
                      <div key={obj.id}>
                        <Box sx={{
                          display:{xs:'none', sm:'none',md:'block', lg:'block', xl:'block'}
                        }}>
                            <ProjectCard
                                textColour={textColour} //overrides default theme to keep text dark on light paper in dark mode 
                                title={obj.title}
                                summary={obj.summary}
                                result={obj.result}
                                stack={obj.stack}
                                skills={obj.skills}
                                videoUrl={obj.videoUrl}
                                videoMaxWidth={obj.videoMaxWidth}
                            />
                             <AnimalHoloCard 
                                imgUrl={obj.holoAnimalUrl} 
                                imgUrlDark={obj.holoAnimalUrlDark}                               
                            /> 
                        </Box>
                        <Box sx={{maxWidth: '100vw',
                          display:{xs:'block', sm:'block',md:'none', lg:'none', xl:'none'}
                        }}>
                            <ProjectCardSmall
                                textColour={textColour} //overrides default theme to keep text dark on light paper in dark mode 
                                title={obj.title}
                                summary={obj.summary}
                                result={obj.result}
                                stack={obj.stack}
                                skills={obj.skills}
                                videoUrl={obj.videoUrl}
                                videoMaxWidth={obj.videoMaxWidth}
                            />
                            <AnimalHoloCard 
                                imgUrl={obj.holoAnimalUrl} 
                                imgUrlDark={obj.holoAnimalUrlDark}                               
                            /> 
                        </Box>
                      </div>
                  )})
          }
    </div>
  )
}
 