import React from 'react';
import { Stack, Paper, Box, Typography } from '@mui/material';

const pictureStyle = {
    maxHeight: 40,
    objectFit: 'cover',
}

export default function TechSkillsCard() {
  return (
            <Paper elevation={9} sx={{
                display: 'flex', flexFlow: 'column wrap', alignItems: 'center', justifyContent:'stretch', width: '100%', maxWidth: '98vw',
                border: '1px solid lightGrey',p:1, mt:4, mb:4}}
            >
                <Stack gap={1}>
                    <Box sx={{display: 'flex', alignSelf: 'center'}}>
                        <Typography sx={{p:1}} variant='subtitle2' color='primary.main'>
                            Technical Expertise
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex'}} gap={1}>
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/react.png' 
                            alt='react logo' 
                        />
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/js.png' 
                            alt='javascript logo' 
                        />
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/html.png' 
                            alt='html5 logo' 
                        />
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/css.png' 
                            alt='css3 logo' 
                        />
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/node.png' 
                            alt='node logo logo' 
                        />
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/jest.png' 
                            alt='Jest logo' 
                        />
                        <img style={pictureStyle} 
                            loading='lazy' 
                            src='./images/gcp.png' 
                            alt='Google Cloud logo' 
                        />
                        </Box>
                </Stack>
            </Paper>
    )
}
