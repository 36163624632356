import React from 'react'

const videoStyle = {
  maxWidth: '100vw',
}

export default function TitleBackgroundImgCardSm() {
  return (
    <video style={videoStyle} autoPlay playsInline muted loop loading='lazy' poster='./images/deerPosterSm.jpg'>
      <source src='./videos/deerWinkSm.mp4' type="video/mp4"/>
    </video>
  )
}
