import React from 'react'
import { Box, Typography } from '@mui/material'

export default function VideoSummaryCard({summary}) {
  return (
    <>
        <Box>
            <Typography variant='subTitle2' component='span'>
                <Typography sx={{mr:1}} color='secondary.main' component='span'>
                    Summary 
                </Typography> 
                    {summary}
            </Typography>
        </Box>
    </>
  )
}
