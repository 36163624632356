import React from 'react'
import { Typography, Paper, Box } from '@mui/material'
import ProjectVideoCard from './ProjectVideoCard'
import VerticalDividerCard from './VerticalDividerCard'

export default function ProjectCard({
        title,
        summary,
        result,
        stack,
        skills,
        videoUrl,
        videoMaxWidth,
        textColour, 
    }){
    
    return (
        <>
        <Paper elevation={0} sx={{maxWidth: '850px'}}>
            <Box sx={{mb:12}}>
                <VerticalDividerCard text={'scroll software'} />
            </Box>
                <Typography sx={{mb:2, display:'flex', justifyContent: 'center'}} 
                    color='primary.main' 
                    variant='subtitle1' component='span'
                >
                    Project:
                    <Typography sx={{ml:1}} color='text.dark' variant='subtitle1' component='span'>
                        {title}
                    </Typography>
                </Typography>
                
                <Paper 
                    elevation={9}
                    sx={{
                        border:'1px solid lightGrey',
                        display: 'flex',
                        flexFlow: 'row noWrap',
                        backgroundColor: '#fff', 
                        width: '850px', 
                        height: '500px'}}
                    >
                        <Box sx={{
                                display: 'flex',
                                flexFlow: 'column noWrap',
                                justifyContent: 'space-between',
                                alignItems: 'stretch',
                                width: '60%',
                        }}>
                            <Typography sx={{p:2}} variant='subtitle2' color='secondary.dark' component='div'>
                                Overview
                            </Typography>
                           
                            <Typography 
                                variant='body1'
                                color={textColour} 
                                component='div' 
                                sx={{
                                    p:2, pt:1,
                                    display: 'flex',
                                    height: '50%',
                            }}>
                                {summary}
                            </Typography>
                            <Typography sx={{p:2}} variant='subtitle2' color='secondary.dark' component='div'>
                                Result
                            </Typography>
                            <Typography 
                                variant='body1' 
                                color={textColour} 
                                component='div' 
                                sx={{
                                    p:2,
                                    display: 'flex',
                                    height: '50%',
                            }}>
                                {result}
                            </Typography>
                        </Box>       
                    
                    <Box sx={{
                        p:1, pt:1,
                        display: 'flex',
                        overFlow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40%',
                    }}>
                      <ProjectVideoCard videoUrl={videoUrl} videoMaxWidth={videoMaxWidth} />
                    </Box>
                </Paper>
                <Box sx={{mt:2}}>
                    <Typography variant='subTitle2' component='span'>
                        <Typography sx={{mr:1}} color='secondary.main' component='span'>
                            Solutions Stack: 
                        </Typography> 
                        {stack}
                    </Typography>
                </Box>
                <Box sx={{mt:2}}>
                    <Typography variant='subTitle2' component='span'>
                        <Typography sx={{mr:1}} color='secondary.main' component='span'>
                            Skills: 
                        </Typography> 
                            {skills}
                    </Typography>
                </Box>
            </Paper>
        </>
  )
}
