import React, { useState, useEffect} from 'react'
import { Paper, useTheme } from '@mui/material'

const pictureStyle = {
  width: '500px',  
  maxWidth: '95vw',
}

export default function AnimalHoloCard({imgUrl, imgUrlDark}) {
  const [imgSrc, setImgSrc] = useState(imgUrl); //place holder to allow for use of darkMode images
  const [themeMode, setThemeMode] = useState();
  const { palette } = useTheme(); 
  useEffect(() => {
      palette.mode === 'dark' ? setThemeMode('dark') : setThemeMode('light')
  }, [imgUrl, imgUrlDark, palette.mode]);

  return (
    <>
      {themeMode === 'light' &&
          <Paper elevation={0} sx={{mb:12, display: 'flex', alignItems:'center', justifyContent:'center'}}>
              <img style={pictureStyle} 
                  loading='lazy' 
                  src={imgSrc} 
                  alt='holographic animal' 
              />
          </Paper>
      }
    </>
  )
}
