import React from 'react';
import { Stack, Box } from '@mui/material';
import MainHeaderTile from '../components/tiles/MainHeaderTile';
import NameAndTitleTile from '../components/tiles/NameAndTitleTile';
import ProjectsTile from '../components/tiles/ProjectsTile';
import MottoParagraph from '../components/cards/MottoParagraph';
import VideosTile from '../components/tiles/VideosTile';

export default function HomePage(){  
    return (
        <Box sx={{mt:3}}> 
            <Stack
                direction='column'
                sx={{alignItems: 'center', p:0}}
                gap={2}
            >
                <MainHeaderTile />
                <NameAndTitleTile />
                <MottoParagraph />
                <ProjectsTile />
                <VideosTile />
            </Stack>
        </Box> 
    );
}