import { Box } from '@mui/material'
import React from 'react'

export default function TitleBackgroundImgCardLg() {
  return (
    <Box sx={{maxWidth:'98vw', overflow: 'hidden'}}>
        <video autoPlay playsInline muted loop loading='lazy' style={{maxWidth: '1200px'}} poster='./images.deerPoster.jpg'>
            <source src='./videos/deerWink1888x894.mp4' type="video/mp4"/>
        </video>
    </Box>
    )
}
