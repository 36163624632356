import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

const pictureStyle = {
    width: 500,
    height: 500,
    maxWidth: '90vw',
    borderRadius: '5%',
    objectFit: 'cover',
    overflow: 'hidden',
}

export default function ProfilePictureCard({imgUrl, imgHoverUrl}) {
  const [profilePic, setProfilePic] = useState('');
  const [pictureMsg, setPictureMsg] = useState('')
  const messageOne = 'Click the pic for The Girl...';
  const messageTwo = 'Click the pic for The Boy...'

  const handleClick = () => {
    profilePic === imgUrl ? setProfilePic(imgHoverUrl) : setProfilePic(imgUrl);
    pictureMsg === messageOne ? setPictureMsg(messageTwo) : setPictureMsg(messageOne);
  }

  useEffect(() => {
    setProfilePic(imgUrl); 
    setPictureMsg(messageOne);
  }, [imgUrl]);

  return (
    <>
      <Box sx={{mt:5, maxWidth:'450px', display:'flex', flexFlow: 'column wrap', alignContent: 'center'}}>
          <img style={pictureStyle} 
              onClick={handleClick}
              loading='lazy' 
              src={profilePic} 
              alt='michael mallette' 
          />
          <Typography sx={{mt:1, display:'flex', alignSelf: 'center'}} variant='caption' color='secondary.main'>
            {pictureMsg}
          </Typography>
      </Box>
    </>
  )
}
