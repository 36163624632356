import React from 'react'
import { Box } from '@mui/material'
import ReferenceCard from '../cards/ReferenceCard'
import { references } from '../../data/references'

export default function ReferencesTile() {
    const { title, company, name, jobTitle, totalPages, pageOne, pageTwo } = references[0];
    return (
            <Box sx={{
                display: 'flex', flexFlow: 'column noWrap',justifyContent: 'center',alignItems: 'stretch',
                maxWidth: '98vw'
            }}>
                <ReferenceCard 
                    title={title}
                    name={name}
                    jobTitle={jobTitle}
                    company={company}
                    totalPages={totalPages}
                    pageOne={pageOne}
                    pageTwo={pageTwo}
                /> 
            </Box>
    )
}
