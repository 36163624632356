import React from 'react'
import { Box, Typography } from '@mui/material'

export default function VideoStackSkillsCard({stack, skills}) {
  return (
   <>
        <Box sx={{mt:2}}>
            <Typography variant='subTitle2' component='span'>
                <Typography sx={{mr:1}} color='secondary.main' component='span'>
                    Solutions Stack: 
                </Typography> 
                    {stack}
            </Typography>
        </Box>
        <Box sx={{mt:2}}>
            <Typography variant='subTitle2' component='span'>
                <Typography sx={{mr:1}} color='secondary.main' component='span'>
                    Skills: 
                </Typography> 
                    {skills}
            </Typography>
        </Box>
    </>
  )
}
