import React from 'react'
import { Box, Stack, Typography } from '@mui/material'


export default function TitleCard({title, titleTwo, titleThree, titleFour}) {
  return (
    <>
      {/* md - xl screen title */}
        <Typography sx={{
            display: {xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block'},
            textAlign: 'center'}} variant='h3' color='text.main' component='h3'
        >
          <Stack direction='column' gap={2}>
            <Box>{title}</Box>
            <Box>{titleTwo}</Box>
            <Box>{titleThree}</Box>
            <Box>{titleFour}</Box>
          </Stack>
        </Typography>
      {/* lg - xl screen title */}
      <Typography sx={{
          display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'},
          textAlign: 'center'}} variant='h3' color='text.main' component='h3'
      >
          <Stack direction='column' gap={2}>
            <Box>{title}</Box>
            <Box>{titleTwo}</Box>
            <Box>{titleThree}</Box>
            <Box>{titleFour}</Box>
          </Stack>
      </Typography>
    </>
  )
}
