import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';

const paperStyle = {
  justifyContent: 'stretch',
  alignItems: 'center',
  maxWidth: 380,
  padding: 8, 
  margin: 'auto',
  marginTop: 120, 
}

export default function ErrorPage() {
  const navigate = useNavigate(); 
  
  const handleClickHome = () => {
    navigate('/');
  }
  
  return (
    <Paper style={paperStyle} onClick={handleClickHome} elevation={6}>
      <Typography variant='h6' component="div">
          <span style={{color: 'white'}} onClick={handleClickHome}>Let's get you back home.</span>
      </Typography>
    </Paper>
  );
}
