import React from 'react';
import { Box } from '@mui/material';
import ReferencesTile from '../components/tiles/ReferencesTile';

export default function ReferencePage(){  
    return (
        <Box sx={{mt:3}}> 
           <ReferencesTile />
        </Box> 
    );
}