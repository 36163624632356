import React from 'react'
import { Box, Paper } from '@mui/material'
import VideoStackSkillsCard from './VideoStackSkillsCard'
import VideoDemoCard from './VideoDemoCard'
import VideoSummaryCard from './VideoSummaryCard'
import VerticalDividerCard from './VerticalDividerCard'
import VideoTitleCard from './VideoTitleCard'

export default function VideosCard({title, summary, videoUrl, poster, stack, skills}) {
  return (
    <Box sx={{maxWidth: '100wv'}}>
        <Paper 
            elevation={0} 
            sx={{maxWidth: '850px'}}
        >
            <Box sx={{mb:12}}>
                <VerticalDividerCard text={'scroll creative'} />
            </Box>
            <VideoTitleCard
                title={title}
            />
            <Paper elevation={0} sx={{p:1, mt:2, mb:2}}>
                <VideoSummaryCard
                    title={title}
                    summary={summary}
                />
            </Paper>
            <Paper elevation={9} sx={{p: 1, border: '1px solid lightGrey'}}>
                <VideoDemoCard
                    videoUrl={videoUrl}
                    poster={poster}
                />
            </Paper>
            <Paper elevation={0} sx={{m:0,p:0}}>
                <VideoStackSkillsCard 
                    stack={stack} 
                    skills={skills}
                />
            </Paper>   
        </Paper>
     </Box>
  )
}
