import React from 'react'
import NameCard from '../cards/NameCard'
import TitleCard from '../cards/TitleCard'
import { Stack } from '@mui/material'
import { profileData } from '../../data/profileData'

export default function NameAndTitleTile() {
  const { name, title, titleTwo, titleThree, titleFour} = profileData;
  return (
        <Stack sx={{mb:8}} direction='column' gap={2}>
            <NameCard name={name} />    
            <TitleCard 
              title={title}
              titleTwo={titleTwo}
              titleThree={titleThree}
              titleFour={titleFour}
            />
        </Stack>
  )
}
