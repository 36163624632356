import React from 'react'
import { Typography } from '@mui/material' 

export default function NameCard({name}) {
  return (
    <>
    {/* md - xl screen title */}
    <Typography sx={{
        display: {xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block'},
        textAlign: 'center'}} variant='h2' color='primary.main' component='h2'
    >
      {name}
    </Typography>
    {/* lg - xl screen title */}
    <Typography sx={{
        display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'},
        textAlign: 'center'}} variant='h3' color='primary.main' component='h3'
    >
      {name}
    </Typography>
  </>
  )
}
