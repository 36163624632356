import React, { createContext, useState, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { getDesignTokens } from './theme/theme';
import router from './router';

export const ColorModeContext = createContext({toggleColorMode: () => {}});

function App() {
      const [mode, setMode] = useState('light');
      const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
      const colorMode = useMemo(
            () => ({
                  toggleColorMode: () => {
                        setMode((prevMode) =>
                              prevMode === 'light' ? 'dark' : 'light',
                        );
                  },
      }),[]);

return (
      <>
            <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <RouterProvider router={router} />
                  </ThemeProvider>
            </ColorModeContext.Provider>
      </>
    );
}

export default App;
 