import React from 'react';
import { Typography, Stack, Paper, Box } from '@mui/material';

const pictureStyle = {
    maxHeight: 40,
    objectFit: 'cover',
}

export default function DesignSkillsCard() {
  return (
            <Paper elevation={9} sx={{
                display: 'flex', flexFlow: 'column wrap', alignItems: 'center', justifyContent:'stretch', width: '100%', maxWidth: '98vw',
                border: '1px solid lightGrey',p:1, mt:4, mb:4}}
            >
                <Stack gap={1}>
                    <Box sx={{display: 'flex', alignSelf: 'center'}}>
                        <Typography sx={{p:1}} variant='subtitle2' color='primary.main'>
                            Design Expertise
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex'}} gap={1}>
                       <img style={pictureStyle} 
                        loading='lazy' 
                        src='./images/ai.png' 
                        alt='javascript logo' 
                    />
                    <img style={pictureStyle} 
                        loading='lazy' 
                        src='./images/ae.png' 
                        alt='react logo' 
                    />
                    <img style={pictureStyle} 
                        loading='lazy' 
                        src='./images/pr.png' 
                        alt='html5 logo' 
                    />
                    <img style={pictureStyle} 
                        loading='lazy' 
                        src='./images/ps.png' 
                        alt='css3 logo' 
                    />
                        </Box>
                </Stack>
            </Paper>
    )
}
