import React, { useState, useEffect} from 'react'
import { videos } from '../../data/projects'
import { Box, useTheme } from '@mui/material'
import AnimalHoloCard from '../cards/AnimalHoloCard'
import VideosCard from '../cards/VideosCard'
import VideosCardSmall from '../cards/VideosCardSmall'

export default function VideosTile() {
    const [textColour, setTextColour] = useState('text.dark');
    const { palette } = useTheme();
  
    useEffect(() => {
        palette.mode === 'dark' ? setTextColour('black') : setTextColour('text.dark')
    }, [palette.mode]);
  
return (
    <div>
        {videos !== undefined && 
            videos?.map((obj) => {
                return (
                    <div key={obj.id}>
                        <Box sx={{display:{xs:'none', sm:'none',md:'block', lg:'block', xl:'block'}}}>
                            <VideosCard
                                textColour={textColour} //overrides default theme to keep text dark on light paper in dark mode 
                                title={obj.title}
                                summary={obj.summary}
                                result={obj.result}
                                stack={obj.stack}
                                skills={obj.skills}
                                videoUrl={obj.videoUrl}
                            />
                            <AnimalHoloCard 
                                imgUrl={obj.holoAnimalUrl} 
                                imgUrlDark={obj.holoAnimalUrlDark}                               
                            /> 
                        </Box>
                        <Box sx={{display:{xs:'block', sm:'block',md:'none', lg:'none', xl:'none'}, 
                            maxWidth: '100vw'
                        }}>
                            <VideosCardSmall
                                textColour={textColour} //overrides default theme to keep text dark on light paper in dark mode 
                                title={obj.title}
                                summary={obj.summary}
                                result={obj.result}
                                stack={obj.stack}
                                skills={obj.skills}
                                videoUrl={obj.videoUrl}
                            />
                            <AnimalHoloCard 
                                imgUrl={obj.holoAnimalUrl}
                                imgUrlDark={obj.holoAnimalUrlDark} 
                            /> 
                        </Box>
                    </div>
                )})
        }
    </div>
  )
}
