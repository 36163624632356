import React from 'react'
import { Box } from '@mui/material'

export default function VideoDemoCard({videoUrl, poster}) {
return (
    <Box sx={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
    }}>
        <video 
            style={{
                display: 'flex', 
                flex:'1 1', 
                borderRadius: '8px', 
                width: '850px', 
                maxWidth:'90vw',
            }}
            controls 
            preload='auto' 
            loading='lazy' 
            poster={poster}
        >
            <source src={videoUrl} type="video/mp4"/>
        </video>
    </Box>
    )
}