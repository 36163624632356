import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header';
import { Box } from '@mui/material';
import Footer from './Footer';

const item = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100vw',
}

const Root = () => {
    return (
        <>
            {/* display on all screen sizes */}
            <Box style={{marginTop: 64}}> 
                <Header />
            </Box>
            <div style={item}>
                <Outlet />
            </div>
            <Box sx={{
                mt:4, mb:4, 
                paddingTop: 8
            }}>
                <Footer />
            </Box>
        </> 
    );
};

export default Root;