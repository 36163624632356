import React from 'react'
import { Typography, Paper } from '@mui/material'
import { profileData } from '../../data/profileData'

export default function MottoParagraph() {
  const {motto, mottoTitle} = profileData;
   return (
    <Paper elevation={0} sx={{textAlign: 'center', p:3, m:1, maxWidth: '1100px'}}>
        <Typography sx={{mb:3,mt:-3}} variant='h3' color='primary.main' component='div'>
            {mottoTitle}
        </Typography>
        <Typography sx={{mb:2}} color='text.main' fontSize='1.05rem' variant='subtitle2'>
            {motto}
        </Typography>
    </Paper>
  ) 
}
